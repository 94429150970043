import axios from '~/utils/axios'

// Authentication
export const signUpAPI = (payload) => axios.post('/api/v1/registrations', payload)

export const registerDepartmentAPI = (payload) => {
  return axios.post('/api/v1/registrations/register_department', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export const logoutAPI = () => axios.post('/api/v1/users/logout')
export const getWhoamiAPI = () => axios.get('/api/v1/users/whoami')
export const loginAPI = (payload) => {
  payload.grant_type = 'password'
  return axios.post('/oauth/token', payload)
}

// User
export const updateUserAPI = (payload) => axios.put('/api/v1/users', payload)
export const changePasswordAPI = (payload) => axios.put('/api/v1/users/password', payload)

// Upload image
export const uploadImageAPI = (payload) => {
  return axios.post('/api/v1/images', payload, {
    'Content-Type': 'multipart/form-data',
  })
}

// Producer
export const getProducerAPI = (id) => axios.get(`/api/v1/producers/${id}`)
export const createProducerAPI = (payload) => axios.post('/api/v1/producers', payload)
export const updateProducerAPI = (payload) => axios.put(`/api/v1/producers/${payload.id}`, payload)
export const deleteProducerAPI = (id) => axios.delete(`/api/v1/producers/${id}`)
export const searchProducersAPI = (params) =>
  axios.get('/api/v1/admin/producers', {
    params,
  })
export const exportProducersAPI = (payload) => axios.post('/api/v1/admin/producers/export', payload)
export const bulkUpdateProducersAPI = (payload) =>
  axios.put('/api/v1/admin/producers/bulk', payload)

// Producer OEM
export const getOemProducerAPI = (id) => axios.get(`/api/v1/admin/producer_oems/${id}`)
export const getOemProducersAPI = (params) => axios.get(`/api/v1/admin/producer_oems`, { params })
export const createOemProducerAPI = (payload) => axios.post('/api/v1/admin/producer_oems', payload)
export const updateOemProducerAPI = (payload) =>
  axios.put(`/api/v1/admin/producer_oems/${payload.id}`, payload)
export const searchOemProducersAPI = (params) =>
  axios.get('/api/v1/admin/producer_oems', {
    params,
  })

// Product Types
export const getProductTypesAPI = () => axios.get(`/api/v1/product_types`)

// Product
export const getProductAPI = (params) => axios.get(`/api/v1/products/${params.id}`)
export const getProductsAPI = (params = {}) =>
  axios.get('/api/v1/products', {
    params,
  })
export const searchProductsAPI = (params) =>
  axios.get('/api/v1/products', {
    params,
  })
export const createProductAPI = (payload) => axios.post('/api/v1/products', payload)
export const deleteProductAPI = (payload) => axios.delete(`/api/v1/products/${payload.id}`)
export const updateProductAPI = (payload) => axios.put(`/api/v1/products/${payload.id}`, payload)
export const registerSalesProductAPI = (payload) =>
  axios.post(`/api/v1/products/${payload.id}/register`, payload)

export const generateSkuProductAPI = (payload) =>
  axios.post(`/api/v1/products/${payload.id}/generate_sku`, payload)

export const updateSkuProductAPI = (payload) =>
  axios.put(`/api/v1/products/${payload.id}/sku`, payload)

// export const getToken = () => axios.get('/api/v1/admin/oauth/token')

export const getProducersAPI = (params = {}) => {
  Object.keys(params)?.map((i) => !params[i] && delete params[i])
  return axios.get('/api/v1/admin/producers', { params })
}

export const searchListProducersAPI = (params = {}) => {
  Object.keys(params)?.map((i) => !params[i] && delete params[i])
  return axios.get('/api/v1/admin/producers/list', { params })
}

// Category
export const getProductCategoryAPI = () => axios.get('/api/v1/categories')

// Sales
export const getSalesChannelsAPI = () => axios.get(`/api/v1/sale_channels`)
export const registerSalesChannelAPI = (payload) =>
  axios.post(`/api/v1/sale_channels/${payload.id}/register`, payload)

// Posts
export const getPostsAPI = (params) => axios.get('/api/v1/client/posts', { params })
export const getPostAPI = (params) => axios.get(`/api/v1/client/posts/${params.id}`)

// Post category
export const getPostCategoriesAPI = () => axios.get('/api/v1/client/category_posts')

// Traceabilities
export const getProductBatchAPI = (params) => axios.get(`/api/v1/traceabilities/${params.id}`)
export const getProductBatchesAPI = (params = {}) =>
  axios.get('/api/v1/traceabilities', {
    params,
  })
export const createProductBatchAPI = (payload) => axios.post('/api/v1/traceabilities', payload)
export const updateProductBatchAPI = (payload) =>
  axios.put(`/api/v1/traceabilities/${payload.id}`, payload)
export const deleteProductBatchAPI = (payload) =>
  axios.delete(`/api/v1/traceabilities/${payload.id}`)

// Admin
export const adminCreatePostAPI = (payload) => axios.post('/api/v1/admin/posts', payload)
export const adminUpdatePostAPI = (id, params) => axios.put(`/api/v1/admin/posts/${id}`, params)
export const adminDeletePostAPI = (id) => axios.delete(`/api/v1/admin/posts/${id}`)
export const adminGetPostsAPI = (params = {}) => {
  return axios.get('/api/v1/admin/posts', { params })
}
export const adminGetPostAPI = (id) => axios.get(`/api/v1/admin/posts/${id}`)

// FAQ
export const getFAQsAPI = (params) => axios.get('/api/v1/client/faqs', { params })
export const getFAQAPI = (params) => axios.get(`/api/v1/admin/faqs/${params.id}`)
export const createFAQAPI = (payload) => axios.post('/api/v1/admin/faqs', payload)
export const updateFAQAPI = (payload) => axios.put(`/api/v1/admin/faqs/${payload.id}`, payload)
export const deleteFAQAPI = (payload) => axios.delete(`/api/v1/admin/faqs/${payload.id}`)

// Sale channel references
export const getSaleChannelReferencesAPI = (params) =>
  axios.get('/api/v1/sale_channel_references', { params })
export const getSaleChannelReferenceAPI = (params) =>
  axios.get(`/api/v1/sale_channel_references/${params.id}`)
export const updateSaleChannelReferenceAPI = (payload) =>
  axios.put(`/api/v1/sale_channel_references/${payload.id}`, payload)
export const syncSaleChannelReferenceAPI = (id) =>
  axios.post(`/api/v1/sale_channel_references/${id}/sync`)
export const getSaleChannelReferenceByProductAPI = (product_id) =>
  axios.get(`/api/v1/sale_channel_references/by_product`, { params: { product_id } })
export const getSaleChannelReferencesIdByProductIdAPI = (params) =>
  axios.get(`/api/v1/sale_channel_references/sale_channel_referenceable`, { params })

// Vote
export const voteAPI = (payload) => axios.post(`/api/v1/votes`, payload)
export const getVoteAPI = (params) => axios.get(`/api/v1/votes`, { params })
export const deleteVoteAPI = (id) => axios.delete(`/api/v1/votes/${id}`)

// Forgot password
export const forgotPasswordAPI = (payload) => axios.post('/api/v1/users/forgot_password', payload)
export const resetPasswordAPI = (payload) => axios.post('/api/v1/users/reset_password', payload)

// Service
export const registerServiceAPI = (payload) =>
  axios.post('/api/v1/registrations/register_service', payload)
export const updateRegisterServiceAPI = (payload) =>
  axios.put(`/api/v1/admin/register_services/bulk`, payload)
export const lockAllUserRegisterServiceAPI = (payload) =>
  axios.put(`/api/v1/admin/user_register_services/lock_all`, payload)

// Users
export const adminCreateUserAPI = (payload) => axios.post('/api/v1/admin/users', payload)
export const adminUpdateUserAPI = (payload) =>
  axios.put(`/api/v1/admin/users/${payload.id}`, payload)
export const adminDeleteUserAPI = (payload) => axios.delete(`/api/v1/admin/users/${payload.id}`)
export const adminGetUsersAPI = (params = {}) => {
  return axios.get('/api/v1/admin/users', { params })
}
export const adminGetUserAPI = (params) => axios.get(`/api/v1/admin/users/${params.id}`)
export const createUserGoogleDriveAPI = (id) =>
  axios.post(`/api/v1/admin/users/${id}/create-gdrive-folder`)

// Page view
export const adminGetPageViewsAPI = (params) => axios.get(`/api/v1/admin/page_views`, { params })
export const adminCreatePageViewAPI = (payload) => axios.post(`/api/v1/admin/page_views`, payload)
export const adminUpdatePageViewAPI = (payload) =>
  axios.put(`/api/v1/admin/page_views/${payload.id}`, payload)
export const getPageViewAPI = (params) => axios.get(`/api/v1/client/page_views/${params.id}`)
export const adminDeletePageViewAPI = (params) =>
  axios.delete(`/api/v1/admin/page_views/${params.id}`)

// Services
export const getServicesAPI = () => axios.get(`/api/v1/register_services`)
export const getUserRegisterServicesAPI = (params) =>
  axios.get(`/api/v1/admin/user_register_services`, { params })
export const updateUserRegisterServicesAPI = (payload) =>
  axios.put(`/api/v1/admin/user_register_services/${payload.id}`, payload)

// User Register Service
export const getClientRegisterServicesAPI = (params = {}) =>
  axios.get(`/api/v1/user_register_services`, { params })
export const vietfishGetURSAPI = (payload) =>
  axios.get(`/api/v1/user_register_services/${payload.id}`)
export const userRegisterServiceAPI = (payload) =>
  axios.post(`/api/v1/user_register_services`, payload)
export const userUpdateRegisterServiceAPI = (payload) =>
  axios.put(`/api/v1/user_register_services/${payload.id}`, payload)
export const exportRegisteredServicesAPI = (payload) =>
  axios.post(`/api/v1/admin/user_register_services/export?slug=${payload?.slug}`, payload)
export const userRemoveRegisterServiceAPI = (id) =>
  axios.delete(`/api/v1/user_register_services/${id}`)

// upload attachment
export const uploadAttachmentAPI = (attachment) => {
  return axios.post('/api/v1/attachments', attachment, {
    'Content-Type': 'multipart/form-data',
  })
}

// Booking seminar room
export const getSeminarBookedRoomsAPI = () => axios.get('/api/v1/booking_rooms')
export const bookSeminarRoomsAPI = (payload) => axios.post('/api/v1/booking_rooms', payload)
export const removeBookSeminarRoomsAPI = () => axios.delete('/api/v1/booking_rooms')

export const getPurchaseOrdersAPI = (params) =>
  axios.get('/api/v1/admin/purchase_orders', { params })
export const getPurchaseOrderAPI = (id) => axios.get(`/api/v1/admin/purchase_orders/${id}`)
export const createPurchaseOrderAPI = (payload) =>
  axios.post('/api/v1/admin/purchase_orders', payload)
export const updatePurchaseOrderAPI = (payload) =>
  axios.put(`/api/v1/admin/purchase_orders/${payload?.id}`, payload)

//client-messages
export const getClientMessagesAPI = (params) => axios.get('/api/v1/admin/messages', { params })
export const updateClientMessageAPI = (payload) =>
  axios.put(`/api/v1/admin/messages/${payload.id}`, payload)

export const exportSalesChannelReferencesAPI = (payload) =>
  axios.post(`/api/v1/sale_channel_references/export`, payload)

export const registerClientMessageAPI = (payload) => axios.post('/api/v1/client/messages', payload)
