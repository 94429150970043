import axios from 'axios'
import { logoutAction } from '~/redux/actions/Authentication'
import { store } from '~/redux/store'
import * as Sentry from '@sentry/nextjs'

let baseURL = process.env.API_URL

const instance = axios.create({
  baseURL,
})

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
instance.defaults.timeout = 60000

instance.interceptors.response.use(
  (response) => response.data,
  function (error) {
    if (401 == error?.response?.status && error?.response?.config?.url != '/api/v1/users/logout') {
      store.dispatch(logoutAction())
      window.location.href = '/login'
    } else {
      const errorSerialize = {
        code: error.response?.status,
        message: error.response?.data?.meta?.message,
      }

      Sentry.captureException(errorSerialize)

      return Promise.reject(errorSerialize)
    }
  },
)

export const setToken = (token) => (instance.defaults.headers.common['Authorization'] = token)

export const setReqHeader = ({ locale, platform }) => {
  instance.defaults.headers.common['locale'] = locale
  instance.defaults.headers.common['Platform'] = platform
}

export default instance
