/* eslint-disable @next/next/inline-script-id */
import { NextSeo } from 'next-seo'
import App from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RequireChangePasswordModal from '~/components/requireChangePasswordModal'
import { appWithTranslation } from '~/i18n'
import nextI18NextConfig from '../next-i18next.config'
import NoPermission from '~/pages/no-permission'
import { appInitAction } from '~/redux/actions/App'
import { setPlatformAction } from '~/redux/actions/Layout'
import { persistor, store } from '~/redux/store'
import { setReqHeader } from '~/utils/axios'
import { useGoogleAnalytics } from '~/hooks'
import { ability } from '~/utils/defineAbility'
import TagManager from 'react-gtm-module'
import {
  DTRACK,
  DTRACK_HEADER,
  DTRACK_HOSTS,
  FOODMAP,
  FOODMAP_HEADER,
  FOODMAP_HOSTS,
  PLATFORM_DTRACK,
  PLATFORM_FOODMAP,
  PLATFORM_VIETFISH,
  VIETFISH,
  VIETFISH_HEADER,
  VIETFISH_HOSTS,
} from '~/utils/constants'
import { fb } from '~/utils/fb'
import '~/styles/styles.scss'
import { setCookie } from '~/utils/helper'

const permissionsStrictPlatforms = [DTRACK]
function MyApp({ Component, pageProps }) {
  require('../styles/antd.less')
  useGoogleAnalytics()
  const router = useRouter()
  const adminDisabledPaths = ['/products', '/products/[id]']
  const user = store.getState().user
  const platform = store.getState().layout.platform?.platform
  const [shouldCheckDomain, setShouldCheckDomain] = useState(true)

  function checkDomain(hostname) {
    const htmlTag = document.getElementsByTagName('html')[0]

    setShouldCheckDomain(false)
    if (DTRACK_HOSTS.includes(hostname)) {
      setReqHeader({ platform: DTRACK_HEADER, locale: router.locale })
      store.dispatch(setPlatformAction(PLATFORM_DTRACK))
      htmlTag.setAttribute('data-theme', DTRACK)

      router.push(router.asPath, router.asPath, { locale: 'vi' })
      setCookie('vi')
      return
    }
    if (VIETFISH_HOSTS.includes(hostname)) {
      setReqHeader({ platform: VIETFISH_HEADER, locale: router.locale })
      store.dispatch(setPlatformAction(PLATFORM_VIETFISH))
      htmlTag.setAttribute('data-theme', VIETFISH)
      TagManager.initialize({ gtmId: 'GTM-NWDZ7GX' })

      router.push(router.asPath, router.asPath, { locale: router.locale })
      setCookie(router.locale)
      return
    }
    if (FOODMAP_HOSTS.includes(hostname)) {
      setReqHeader({ platform: FOODMAP_HEADER, locale: router.locale })
      store.dispatch(setPlatformAction(PLATFORM_FOODMAP))
      htmlTag.setAttribute('data-theme', FOODMAP)

      router.push(router.asPath, router.asPath, { locale: 'vi' })
      setCookie('vi')
      return
    }
  }

  const routesNoNeedAuth = [
    '/reset',
    '/login',
    '/forgot-password',
    '/404',
    '/faqs',
    '/services',
    '/services/[slug]',
    '/services/client',
    '/news',
    '/news/[id]',
    '/news/categories/[id]',
    '/register-department',
    '/information-security',
  ]

  useEffect(() => {
    const host = window.location.host

    if (shouldCheckDomain) {
      checkDomain(host)
    }

    fb((FB) => {
      FB.XFBML.parse()
    })

    if (user?.roles?.length > 0 && adminDisabledPaths.includes(router.pathname)) {
      router.replace('/admin/producers')
      return
    }

    if (!routesNoNeedAuth.includes(router.pathname) && !user?.token?.access_token) {
      router.push('/login')
      return
    }

    if (user?.token?.access_token) {
      store.dispatch(appInitAction(user))
    }
  }, [platform])

  const isAccessable = () => {
    const userRoles = user?.roles
    const requiredRoles = pageProps?.requiredRoles
    if (
      !permissionsStrictPlatforms?.includes(platform) ||
      !requiredRoles ||
      requiredRoles?.length === 0 ||
      userRoles?.includes('admin')
    ) {
      return true
    }

    return requiredRoles?.some((i) => ability.can(i.action, i.subject))
  }

  return (
    <>
      <Head>
        <title>{pageProps?.metaTags?.title || platform}</title>
        {pageProps?.responsive ? (
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        ) : (
          <meta name="viewport" content="width=1200" />
        )}
      </Head>
      <NextSeo
        title={pageProps?.metaTags?.title}
        description={pageProps?.metaTags?.desc}
        openGraph={{
          title: pageProps?.metaTags?.title,
          description: pageProps?.metaTags?.desc,
        }}
        additionalMetaTags={[
          {
            property: 'og:image',
            content: pageProps?.metaTags?.image,
          },
          {
            property: 'image',
            content: pageProps?.metaTags?.image,
          },
          {
            property: 'twitter:image',
            content: pageProps?.metaTags?.image,
          },
        ]}
      />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          {isAccessable() ? <Component {...pageProps} /> : <NoPermission {...pageProps} />}
          {user.required_change_password && <RequireChangePasswordModal />}
        </PersistGate>
      </Provider>
    </>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  const defaultProps = appContext.Component.defaultProps
  return {
    ...appProps,
    pageProps: {
      namespacesRequired: [
        ...(appProps.pageProps.namespacesRequired || []),
        ...(defaultProps?.i18nNamespaces || []),
      ],
    },
  }
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
}
export default appWithTranslation(MyApp, nextI18NextConfig)
