import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Dropdown, Layout, Menu, Row } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Avatar from '~/assets/svg/avatar.svg'
import LogoutIcon from '~/assets/svg/logout.svg'
import VietnamFlag from '~/assets/svg/Vietnam.svg'
import Svg from '~/components/Svg'
import { withTranslation, useTranslation } from '~/i18n'
import LogoVF from '~/public/assets/jpeg/vietfish_logo.jpeg'
import { logoutAction } from '~/redux/actions/Authentication'
import { setAccountSetupStepAction } from '~/redux/actions/Layout'
import { saveAccountAction } from '~/redux/actions/User'
import { DTRACK, VIETFISH } from '~/utils/constants'
import s from './defaultLayout.module.css'
import Image from 'next/image'
// import './styles.scss'

function Header({
  t,
  logout,
  setAccountSetupStep,
  producer,
  isAdmin,
  withLogo,
  color,
  platform,
  userLocale,
  saveAccount,
}) {
  const router = useRouter()
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState('vi')

  const onChangeLocale = (locale) => {
    setLanguage(locale)
    router.push(router.asPath, router.asPath, { locale: locale })
    updateUserLocale(locale)
  }

  useEffect(() => {
    i18n?.language && setLanguage(platform !== 'DTrack' ? i18n.language : 'vi')
    if (userLocale && i18n?.language && i18n?.language != userLocale) {
      updateUserLocale(i18n?.language)
      saveAccount({ locale: i18n?.language })
    }

    if (platform === 'DTrack') {
      router.push(router.asPath, router.asPath, { locale: 'vi' })
    }
  }, [])

  const updateUserLocale = (locale) => {
    saveAccount({ locale })
  }

  const locales = {
    vi: { name: 'Tiếng Việt', flag: <VietnamFlag className="flag" /> },
    ...(platform !== DTRACK && {
      en: {
        name: <span style={{ marginLeft: 8 }}>English</span>,
        flag: <Image src={'/assets/png/England.png'} height={21} width={33} />,
      },
    }),
  }

  const menus = [
    {
      name: t('business-information'),
      icon: 'pack',
    },
    {
      name: t('account-information'),
      icon: 'account',
    },
    {
      name: t('reset-pass'),
      icon: 'lock',
    },
  ]

  const adminMenus = [
    {
      name: t('account-information'),
      icon: 'account',
    },
    {
      name: t('reset-pass'),
      icon: 'lock',
    },
  ]

  const dropdownLanguages = (
    <Menu>
      <div className="account-info-title">{t('select-locale')}</div>
      {Object.keys(locales).map((key) => (
        <Menu.Item key={key} onClick={() => onChangeLocale(key)}>
          <div className={s['switch-locale-item']}>
            {locales[key]?.flag}
            {locales[key]?.name}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const dropdownMenu = (
    <Menu>
      <div className="account-info-title">{t('settings')}</div>
      {isAdmin
        ? adminMenus.map((item, index) => (
            <Menu.Item key={index}>
              <Link href="/account-setup">
                <div onClick={() => setAccountSetupStep(index)} className="account-info-item">
                  <Svg name={item.icon} />
                  {item.name}
                </div>
              </Link>
            </Menu.Item>
          ))
        : menus.map((item, index) => (
            <Menu.Item key={index}>
              <Link href="/account-setup">
                <div onClick={() => setAccountSetupStep(index)} className="account-info-item">
                  <Svg name={item.icon} />
                  {item.name}
                </div>
              </Link>
            </Menu.Item>
          ))}
    </Menu>
  )

  const renderLogo = () => {
    switch (platform) {
      case DTRACK:
        return <Svg name="logo" height={50} width={167} />
      case VIETFISH:
        return <img src={LogoVF} alt="logo" />
      default:
        return <></>
    }
  }

  const logoutToggle = () => {
    return logout(() => router.push('/'))
  }

  return (
    <Layout.Header
      className={`site-layout-background header${withLogo ? ' header-with-logo' : ''}`}
      style={{ padding: 0 }}
    >
      {withLogo && (
        <div className="logo-wrapper" style={{ padding: '0.5rem' }}>
          <Link href="/">{renderLogo()}</Link>
        </div>
      )}
      {((withLogo && producer) || !withLogo) && (
        <Row style={{ height: '100%' }}>
          <Col className="section account-info pointer">
            <Dropdown trigger={['click']} overlay={dropdownMenu}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="section">
                  {producer?.name && <div className="fullname">{producer.name}</div>}
                  <div className="role">{isAdmin ? t('admin') : t('producer')}</div>
                </div>
                <Avatar className="avatar" fill={color} />
                <FontAwesomeIcon color="#bdbdbd" icon={faChevronDown} />
              </div>
            </Dropdown>
          </Col>
          <Col className="section account-info pointer">
            <Dropdown trigger={['click']} overlay={dropdownLanguages}>
              <div style={{ display: 'flex', alignItems: 'center' }}>{locales[language].flag}</div>
            </Dropdown>
          </Col>
          <Col className="section pointer">
            <div onClick={logoutToggle}>
              <LogoutIcon />
            </div>
          </Col>
        </Row>
      )}
    </Layout.Header>
  )
}

const mapStateToProps = (state) => ({
  producer: state.producer.data,
  isAdmin: state.user.roles?.length > 0,
  color: state.layout?.platform?.primaryColor || '#029147',
  platform: state.layout?.platform?.platform,
  userLocale: state.user?.locale,
})
const mapDispatchToProps = (dispatch) => ({
  logout: (actionSuccess) => dispatch(logoutAction(actionSuccess)),
  setAccountSetupStep: (payload) => dispatch(setAccountSetupStepAction(payload)),
  saveAccount: (payload) => dispatch(saveAccountAction(payload)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('defaultLayout'),
)(Header)
